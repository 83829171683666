

.stitchbackground { 
    display: inline-flex;
    justify-content: space-around;
    background-color: gray;
    width: 100%;
}

.stitchrow {
    text-align: center;
    border-radius: 25px;
    width: 50%;
}

.stitchcount {
    display:inline;
    text-align: center;
    width: 50%;
}

.stitchtitle {
    margin: 1px;
    font-size: 50px;
}

.buttonleft {
    float: left;            
    background-color: darkgray;
    color: black;
    border: black;
    border-style: solid;
    border-radius: 5px;
    font-size: 36px;
    padding: 50px;
    width: 34%;
    font-weight: bold;
}

.buttonright {
    float: right;
    background-color: darkgray;
    color: black;
    border: black;
    border-style: solid;
    border-radius: 5px;
    font-size: 36px;
    padding: 50px; 
    width: 66%;
    font-weight: bold;
}

.rowinput {
    margin-top: 10px;
}

.stitchreset {
    margin-top: 10px;
}

@media (max-width: 768px) {
    .stitchbackground {
        flex-direction: column;
    }
    .stitchrow {
        width:100%;
        margin-bottom: 5px;
    }
    .stitchcount {
        padding-top:25px;
        width:100%;
    }

}