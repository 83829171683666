.scoreboard {
    display: inline-flex;
    justify-content: space-around;
    background-color: gray;
    padding: 20px;
    width: 100%;
}

.teama h2 {
    font-size: 30px;
    text-decoration: underline;
}

.teamb h2 {
    font-size: 30px;
    text-decoration: underline;
}

.teama {
    text-align: center;
    padding: 10px;
    background-color: lightcoral;
    border-radius: 25px;
    width: 30%;
}

.teamb {
    text-align: center;
    padding: 10px;
    background-color: lightskyblue;
    border-radius: 25px;
    width:30%;
}

.score {
    font-size: 75px;
    font-weight: bold;
    color: black;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 30px;
}

.info {
    text-align: center;
    padding: 5px;
}

.infohead {
    font-size: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.trumpselect {
    font-size: 25px;
    width:160px;
}

.resetbidtrump {
    font-size:15px;
}

.reset {
    margin-top: 25px;
}

.buttons {
    margin-top: 10px;
}

.buttons button {
    font-size: 16px;
    padding: 8px 12px;
    margin-right: 5px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
}

.buttons button:last-child {
    margin-right: 0;
}

.buttons button:hover {
    background-color: #0056b3;
}

.rookpic {
    width: 75px;
}

.bidnum {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
}


@media (max-width: 768px) {
    .scoreboard {
        flex-direction: column;
    }
    .info {
        width:80%;
    }
    .teama {
        margin-bottom: 20px;
        width:80%;
    }
    .teamb {
        width:80%;
    }
}