.homeButton {
  background-color: black;
  border-style: solid;
  border-color: gray;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 10px;
  border-radius: 10px;
}

.rookButton {
background-color: black;
border-style: solid;
border-color: gray;
color: white;
padding: 10px 10px;
text-align: center;
text-decoration: none;
display: inline-block;
font-size: 10px;
border-radius: 10px
}

.rook3Button {
  background-color: black;
  border-style: solid;
  border-color: gray;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 10px;
  border-radius: 10px
  }

  .stitchButton {
  background-color: black;
  border-style: solid;
  border-color: gray;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 10px;
  border-radius: 10px
  }