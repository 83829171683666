.scoreboard3 {
    display: inline-flex;
    justify-content: space-around;
    background-color: gray;
    width: 100%;
}

.teama3 {
    text-align: center;
    background-color: lightcoral;
    border-radius: 25px;
    width:24%;
}

.teamb3 {
    text-align: center;
    background-color: lightskyblue;
    border-radius: 25px;
    width:24%;
}

.teamc3 {
    text-align: center;
    background-color: lightgreen;
    border-radius: 25px;
    width:24%;
}

.info3 {
    width:22%;
    text-align: center;
}

.r3space {
    font-size: 5px;
}

.roundInput {
    font-weight: bold;
}