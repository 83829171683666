.tttbody {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #242730;
    color: white;
    padding-bottom: 300px;
  }
  
  .ttth1 {
    text-align: center;
  }
  
  .board {
    display: grid;
    grid-template-columns: 100px 100px 100px;
    grid-template-rows: 100px 100px 100px;
    cursor: pointer;
    position: relative;
  }
  
  .tile {
    color: currentColor;
    font-size: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .right-border {
    border-right: 0.2em solid #0074a6;
  }
  
  .bottom-border {
    border-bottom: 0.2em solid #0074a6;
  }
  
  .x-hover:hover::after {
    content: "X";
    opacity: 0.4;
  }
  
  .o-hover:hover::after {
    content: "O";
    opacity: 0.4;
  }
  
  .strike {
    position: absolute;
    background-color: darkorange;
  }
  
  .strike-row-1 {
    width: 100%;
    height: 4px;
    top: 15%;
  }
  .strike-row-2 {
    width: 100%;
    height: 4px;
    top: 48%;
  }
  .strike-row-3 {
    width: 100%;
    height: 4px;
    top: 83%;
  }
  .strike-column-1 {
    height: 100%;
    width: 4px;
    left: 15%;
  }
  .strike-column-2 {
    height: 100%;
    width: 4px;
    left: 48%;
  }
  .strike-column-3 {
    height: 100%;
    width: 4px;
    left: 83%;
  }
  .strike-diagonal-1 {
    width: 90%;
    height: 4px;
    top: 50%;
    left: 5%;
    transform: skewY(45deg);
  }
  .strike-diagonal-2 {
    width: 90%;
    height: 4px;
    top: 50%;
    left: 5%;
    transform: skewY(-45deg);
  }
  
  .game-over {
    text-align: center;
    border: #5468ff 8px;
    border-style: dotted;
    padding: 10px;
    margin-top: 50px;
    font-size: 1.5em;
  }
  
  .reset-button {
    margin-top: 50px;
    padding: 20px;
    background-color: #0074a6;
    color: white;
    width: 100%;
    font-size: 1.5em;
  }