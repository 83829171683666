body {
  background-color: gray;
}

.hometop {
  display: flex;
  float: right;
}

.grid-container {
    display: grid;
    column-gap: 10px;
    grid-template-columns: auto auto auto;
    padding: 10px;
  }
  
  .grid-item {
    background-color: lightgrey;
    border: 1px solid black;
    border-radius: 20px;
    padding: 10px;
    font-size: 30px;
    text-align: center;
  }

  .iconpic {
    width: 35px;
    height: 35px;
  }

  .rookicon{
    width: 100px;
  }

  .crocheticon {
    width: 100px;
  }

  .tictactoeicon {
    width: 100px;
  }